import React from 'react'
import Layout from '../components/Layout/Layout'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'
import StayInTouchSection from '../components/_shared/StayInTouchSection/StayInTouchSection'
import StayInSocialSection from '../components/_shared/StayInSocialSection/StayInSocialSection'
import SectionGoogleMaps from '../components/_shared/SectionGoogleMaps/SectionGoogleMaps'

const ContactPage = () => (
  <Layout pageTitle="Kontakt" headerImage={1}>
    <div className="container container--page">
      <StayInTouchSection />
    </div>
    <ContactFormSection transparent />
    <div className="container container--page">
      <StayInSocialSection />
    </div>
    <SectionGoogleMaps />
  </Layout>
)

export default ContactPage
