import React from 'react'
import './StayInSocialSection.scss'

const StayInSocialSection = () => (
  <div className="stayinsocialSection">
    <div className="container container--stayinsocialSection">
      <h3 className="stayinsocialSection__title">OBSERWUJ NAS</h3>
      <div className="stayinsocialSection__content">
        <a href="https://www.facebook.com/">
          <img
            alt="facebook"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAYAAABVsFofAAACy0lEQVR4Xu2YXUhTYRjH/zvOba1lbmx9OYowIsUuItBuyqvoriKKvBjTdELURRFBQUJJXfRBdVlQE/oAocjsMii6jC5KIYKwiaYykaSarM2tnLGLLN12ds571s70/N/r5znv+/zO7/x3dkyhxoan4Mog8MMa95lCuxpmySaTQJmUcBJODjMIR+aRIRzCEUtUmkNzaI4YAZojxo2ZQ3NojhgBmiPGraQyx1a3Ffb6HSj3rofJYsk50fSH9/je/VBsYhVdJQHHsrEantNnkYajZMX73yF84piSUk01usNZtm071l6+DpPNpngQQ8ApX1cFb/ABJLtdMZh0oSHgrLl0Bct3NqoCYwg4Zs8qbHjUC0gS4Swk4Ni9B6s7OlWDMYQ5Tl8LXO1H88KJ9/fh13h4Xl1i4CMiPY/z9mot0O3XytkSgOtIQPb83+4F8bXrjtYZhftLGs5YwI/EpwHh4bQ2ljacNj8SIcLJepPHCCe3/IaBIzlWQHI45khUHjyMlYeaZGNh/MwpJIeHMmpSUxGkYjGtkZK3v2iZU3XrLmy1dXkPpKQg+uI5Ji6eV1KqqaZocLzB+7Bu2qzpsH+ap5714MuNqwW5ltxFFiWcYr3/LEo4kzevIdL7hOZkIzBx4Ryir14STjYC4ZPHEe97u4Tg3O6Ctaa2IAON+puQ/DxckGuVRCCb3W6UVbrmzlKxdz8q9h2QHXCiswM/R0bm1czOzCA5NPjfwaQ3KFogL5xGyb9yw7whE44K2WmODCzCIRwVz9I/pTSH5tAcMQI0R4wbM4fm0BwxAjRHjBszh+bQHDECNEeMGzOH5gia09wGV2u7bPNoqw/JwZDYBgXo0u0bstntgXVLTe4RUinE3rxG+oO6Xks3OHoNrGZfwpGhRTiEo+Zh+ltLc2gOzREjQHPEuDFzaA7NESNAc8S4MXPym1PfLMZ2aXdNT0a7fwPa3gh/okNQWgAAAABJRU5ErkJggg=="
          />
        </a>
      </div>
    </div>
  </div>
)

export default StayInSocialSection
