import React from 'react'
import './SectionGoogleMaps.scss'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

class SectionGoogleMaps extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stores: [{ latitude: 50.081213, longitude: 18.615493 }],
    }
  }

  displayMarkers = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.stores.map((store, index) => {
      return (
        <Marker
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          id={index}
          position={{
            lat: store.latitude,
            lng: store.longitude,
          }}
        />
      )
    })
  }

  render() {
    return (
      <Map
        // eslint-disable-next-line react/prop-types
        google={this.props.google}
        className="map"
        zoom={8}
        initialCenter={{ lat: 50.84, lng: 18.667 }}
      >
        {this.displayMarkers()}
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAf00F6rnW_1YJP6gGHUR4Q7IpiN3f29HY',
  language: 'pl',
})(SectionGoogleMaps)
